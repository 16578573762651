import { faBadgeCheck, faHandSparkles, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Input, Select, Text } from '@sl-it-dev/sl-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import pidClient from '../../api/pidClient';
import styles from './bonusForm.module.scss';

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  const expression = /^\+[1-9]{1}[0-9]{3,14}$/;
  return expression.test(phone.replace(/-|\s/g, ''));
};

const BonusForm = ({ showWarranty, orderNumber, pid, dealer, expired, statusReceivedCallback }) => {
  const [touchedSalutation, setTouchedSalutation] = useState(false);
  const [salutation, setSalutation] = useState('');

  const [touchedFirstname, setTouchedFirstname] = useState(false);
  const [firstname, setFirstname] = useState('');

  const [touchedLastname, setTouchedLastname] = useState(false);
  const [lastname, setLastname] = useState('');

  const [touchedStreet, setTouchedStreet] = useState(false);
  const [street, setStreet] = useState('');

  const [touchedZip, setTouchedZip] = useState(false);
  const [zip, setZip] = useState('');

  const [touchedCity, setTouchedCity] = useState(false);
  const [city, setCity] = useState('');

  const [touchedEmail, setTouchedEmail] = useState(false);
  const [email, setEmail] = useState('');

  const [touchedCountry, setTouchedCountry] = useState(false);
  const [country, setCountry] = useState('');

  const [phone, setPhone] = useState('');
  const [conditions, setConditions] = useState(!showWarranty);
  const [newsletter, setNewsletter] = useState(false);

  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [loading, setLoading] = useState(true);
  const [usedAt, setUsedAt] = useState(null);

  useEffect(() => {
    pidClient.get(`/product/${pid}/bonus`).then((res) => {
      const { usedAt } = res.data;
      setUsedAt(usedAt);
      setLoading(false);
      statusReceivedCallback(null === usedAt);
    });
  }, []);

  const addError = (field) => {
    setErrors((errors) => [...errors, field]);
  };

  const removeError = (field) => {
    setErrors((errors) => errors.filter((errorField) => errorField !== field));
  };

  useEffect(() => {
    if (!touchedSalutation) {
      setTouchedSalutation(true);

      return;
    }

    if ('' === salutation) {
      addError('salutation');
    } else {
      removeError('salutation');
    }
  }, [salutation]);
  useEffect(() => {
    if (!touchedFirstname) {
      setTouchedFirstname(true);

      return;
    }

    if ('' === firstname) {
      addError('firstname');
    } else {
      removeError('firstname');
    }
  }, [firstname]);
  useEffect(() => {
    if (!touchedLastname) {
      setTouchedLastname(true);

      return;
    }

    if ('' === lastname) {
      addError('lastname');
    } else {
      removeError('lastname');
    }
  }, [lastname]);
  useEffect(() => {
    if (!touchedStreet) {
      setTouchedStreet(true);

      return;
    }

    if ('' === street) {
      addError('street');
    } else {
      removeError('street');
    }
  }, [street]);
  useEffect(() => {
    if (!touchedZip) {
      setTouchedZip(true);

      return;
    }

    if (3 >= zip.length) {
      addError('zip');
    } else {
      removeError('zip');
    }
  }, [zip]);
  useEffect(() => {
    if (!touchedCity) {
      setTouchedCity(true);

      return;
    }

    if ('' === city) {
      addError('city');
    } else {
      removeError('city');
    }
  }, [city]);
  useEffect(() => {
    if (!touchedEmail) {
      setTouchedEmail(true);

      return;
    }

    if ('' === email || !validateEmail(email)) {
      addError('email');
    } else {
      removeError('email');
    }
  }, [email]);
  useEffect(() => {
    if (!touchedCountry) {
      setTouchedCountry(true);

      return;
    }

    if ('' === country) {
      addError('country');
    } else {
      removeError('country');
    }
  }, [country]);

  useEffect(() => {
    if ('' === phone) {
      removeError('phone');

      return;
    }

    if (!validatePhone(phone)) {
      addError('phone');
    } else {
      removeError('phone');
    }
  }, [phone]);

  const onSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);
    if (
      '' !== salutation &&
      '' !== firstname &&
      '' !== lastname &&
      '' !== street &&
      '' !== zip &&
      '' !== city &&
      '' !== email &&
      validateEmail(email) &&
      (phone === '' || validatePhone(phone)) &&
      false !== conditions
    ) {
      const path = showWarranty ? '/cd/pmuklvieeua9qbqvrzuca' : '/cd/mv3ollvpeeua9qbqvrzuca';
      const formData = {
        email,
        postalAddress: {
          salutation,
          firstname,
          lastname,
          street,
          zip,
          city,
        },
        country,
        phone,
        dealer,
        orderId: orderNumber,
        newsletterAndLeadTransfer: {
          subscribeToNewsletter: newsletter,
          leadTransfer: false,
        },
      };

      pidClient
        .post(path, formData)
        .then((res) => {
          const now = new Date(Date.now());
          setSubmitting(false);
          setUsedAt(moment().format('DD.MM.YYYY'));
        })
        .catch((err) => console.log(err));

      return;
    }

    '' === salutation ? addError('salutation') : removeError('salutation');
    '' === firstname ? addError('firstname') : removeError('firstname');
    '' === lastname ? addError('lastname') : removeError('lastname');
    '' === street ? addError('street') : removeError('street');
    '' === zip ? addError('zip') : removeError('zip');
    '' === city ? addError('city') : removeError('city');
    '' === email ? addError('email') : removeError('email');
    '' === country ? addError('country') : removeError('country');
    false === conditions ? addError('conditions') : removeError('conditions');
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      {loading}
      {!loading && null === usedAt && !expired && (
        <form onSubmit={onSubmit}>
          <div className={styles.row}>
            <div className={styles.oneThirdColumn}>
              <Select
                id='salutation'
                required={true}
                label='Anrede'
                onChange={(value) => setSalutation(value)}
                theme='purpleInverted'
                value={salutation}
                isValid={-1 === errors.indexOf('salutation')}
                options={[
                  { value: '', label: 'Bitte wählen' },
                  { value: '102690000', label: 'Herr' },
                  { value: '102690001', label: 'Frau' },
                  { value: '102690003', label: 'Herr und Frau' },
                  { value: '102690004', label: 'Divers / keine Angabe' },
                ]}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.doubleColumn}>
              <Input
                id='firstname'
                type='text'
                required={true}
                label='Vorname'
                onChange={(value) => setFirstname(value)}
                theme='purpleInverted'
                value={firstname}
                isValid={-1 === errors.indexOf('firstname')}
              />
            </div>
            <div className={styles.doubleColumn}>
              <Input
                id='lastname'
                type='text'
                required={true}
                label='Nachname'
                onChange={(value) => setLastname(value)}
                theme='purpleInverted'
                value={lastname}
                isValid={-1 === errors.indexOf('lastname')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Input
                id='street'
                type='text'
                required={true}
                label='Straße und Hausnummer'
                onChange={(value) => setStreet(value)}
                theme='purpleInverted'
                value={street}
                isValid={-1 === errors.indexOf('street')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.oneThirdColumn}>
              <Input
                id='postalCode'
                type='text'
                required={true}
                label='PLZ'
                onChange={(value) => setZip(value)}
                theme='purpleInverted'
                value={zip}
                isValid={-1 === errors.indexOf('zip')}
              />
            </div>
            <div className={styles.twoThirdColumn}>
              <Input
                id='city'
                type='text'
                required={true}
                label='Stadt'
                onChange={(value) => setCity(value)}
                theme='purpleInverted'
                value={city}
                isValid={-1 === errors.indexOf('city')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Input
                id='email'
                type='email'
                required={true}
                label='E-Mail'
                onChange={(value) => setEmail(value)}
                theme='purpleInverted'
                value={email}
                isValid={-1 === errors.indexOf('email')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Input
                id='phone'
                type='phone'
                required={false}
                label='Telefonnummer'
                onChange={(value) => setPhone(value)}
                theme='purpleInverted'
                value={phone}
                isValid={-1 === errors.indexOf('phone')}
              />
              <Text>Bitte geben Sie die Telefonnummer im Format +49 1234 56789 an.</Text>
            </div>
          </div>

          <div className={styles.row} style={{ marginTop: '-30px' }}>
            <div className={styles.singleColumn}>
              <Select
                id='country'
                required={true}
                label='Land'
                onChange={(value) => setCountry(value)}
                theme='purpleInverted'
                value={country}
                isValid={-1 === errors.indexOf('country')}
                options={[
                  { value: '', label: 'Bitte wählen' },
                  { value: 'Deutschland', label: 'Deutschland' },
                  { value: 'Österreich', label: 'Österreich' },
                ]}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              {showWarranty && (
                <React.Fragment>
                  <Checkbox
                    id='conditions'
                    required={true}
                    label={[
                      'Ich habe die ',
                      <a target='_blank' href='Garantiebedingungen.pdf'>
                        Garantiebedingungen
                      </a>,
                      ' gelesen und akzeptiere sie.',
                    ]}
                    onChange={(value) => setConditions(value)}
                    value={conditions}
                    isValid={-1 === errors.indexOf('conditions')}
                  />
                  <br />
                </React.Fragment>
              )}
              <Checkbox
                id='newsletter'
                required={false}
                label='Ja, ich möchte in Zukunft weitere Informationen per E-Mail erhalten.'
                onChange={(value) => setNewsletter(value)}
                value={newsletter}
                isValid={true}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Text>
                Ich habe die Datenschutzerklärung zur Kenntnis genommen und bestätige dies mit dem
                Absenden der Nachricht. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung
                meiner Anfrage elektronisch erhoben und gespeichert werden. Die Einwilligung kann
                ich jederzeit für die Zukunft widerrufen.
              </Text>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.singleColumn} ${styles.submitColumn}`}>
              <Button type='submit' disabled={submitting}>
                {!submitting && <React.Fragment>Abschicken</React.Fragment>}
                {submitting && <FontAwesomeIcon icon={faSpinnerThird} spin />}
              </Button>
            </div>
          </div>
        </form>
      )}
      {!loading && null !== usedAt && showWarranty && (
        <Text>
          <span className={styles.bonusFormSubmitted}>
            <FontAwesomeIcon icon={faBadgeCheck} /> Ihre Herstellergarantie wurde am {usedAt}{' '}
            aktiviert. Ihre Bestätigung erhalten Sie in kurzer Zeit per E-Mail.
          </span>
        </Text>
      )}
      {!loading && null !== usedAt && !showWarranty && (
        <Text>
          <span className={styles.bonusFormSubmitted}>
            <FontAwesomeIcon icon={faHandSparkles} /> Sie haben Ihr Reinigungs- und Pflegeset am{' '}
            {usedAt} bestellt.
          </span>
        </Text>
      )}
    </React.Fragment>
  );
};

export default BonusForm;
